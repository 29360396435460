import Vue from "vue";
import VueRouter from "vue-router";
// import Chat from "@/views/Marketing/Chat.vue";
//
//
// const CreateChat = () => import("../views/Marketing/CreateChat.vue")
// const WhatsappChat = () => import("../views/Marketing/WhatsappChat.vue")
// const AddWhatsappTemplate = () => import("../views/Marketing/AddWhatsappTemplate.vue")
// const AddMessageTemplate = () => import("../views/Marketing/AddMessageTemplate.vue")
// const WhatsappTemplate = () => import("../views/Marketing/WhatsappTemplate.vue")
const Login = () => import("../views/Login.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const AdminUser = () => import("../views/Master/Admin/AdminUser.vue");
const CRMRoles = () => import("../views/Master/CrmRoles/CRMRoles.vue");
const CreateCRMRoles = () => import("../views/Master/CrmRoles/CreateCRMRoles.vue");
const BackendRoles = () => import("../views/Master/BackendRole/BackendRoles.vue");
const BuilderDetails = () => import("../views/Master/Builder/BuilderDetails.vue");
const AddBuilder = () => import("../views/Master/Builder/AddBuilder.vue");
const UploadedFile = () => import("../views/Master/UploadedFile.vue");
const State = () => import("../views/LocationMaster/State/State.vue");
const City = () => import("../views/LocationMaster/City/City.vue");
const Locality = () => import("../views/LocationMaster/Locality/Locality.vue");
const Project = () => import("../views/LocationMaster/Project/Project.vue");
const AddState = () => import("../views/LocationMaster/State/AddState.vue");
const AddCity = () => import("../views/LocationMaster/City/AddCity.vue");
const AddLocality = () => import("../views/LocationMaster/Locality/AddLocality.vue");
const AddProject = () => import("../views/LocationMaster/Project/AddProject.vue");
const AddAdminUser = () => import("../views/Master/Admin/AddAdminUser.vue");
const CreateBackendRole = () => import("../views/Master/BackendRole/CreateBackendRole.vue");
const Amenities = () => import("../views/Master/Amenity/Amenities.vue");
const AddAmenities = () => import("../views/Master/Amenity/AddAmenities.vue");
const Logout = () => import("../auth/Logout.vue");
const Review = () => import("../views/Master/Reviews/Review.vue");
const ViewReview = () => import("../views/Master/Reviews/ViewReview.vue");
const LeadEnquiry = () => import("../views/LeadManagement/LeadEnquiry.vue");
const ListProperty = () => import("../views/Services/Property/ListProperty.vue");
const PropertyLeads = () => import("../views/Leads/PropertyLeads.vue");
const BannerLead = () => import("../views/Leads/BannerLead.vue");
const AddBanner = () => import("../views/Services/Banner/AddBanner.vue");
const Blog = () => import("../views/WebPages/Blog/Blog.vue");
const BlogComments = () => import("../views/WebPages/Blog/BlogComments.vue");
const AddBlog = () => import("../views/WebPages/Blog/AddBlog.vue");
const AddCareer = () => import("../views/WebPages/Career/AddCareer.vue");
const Applicant = () => import("../views/Leads/Application/Applicant.vue");
const AddMaps = () => import("../views/WebPages/Maps/AddMaps.vue");
const AddProperty = () => import("../views/Services/Property/AddProperty.vue");
const AddArticle = () => import("../views/WebPages/Article/AddArticle.vue");
const AddNews = () => import("../views/WebPages/News/AddNews.vue");
const ContactEnquiry = () => import("../views/Leads/ContactEnquiry/ContactEnquiry.vue");
const ViewContactEnquiry = () => import("../views/Leads/ContactEnquiry/ViewContactEnquiry.vue");
const AddBank = () => import("../views/Services/BankDetails/AddBank.vue");
const AddLoan = () => import("../views/Services/LoanDetails/AddLoan.vue");
const ListUser = () => import("../views/ClientManagement/User/ListUser.vue");
const EditUser = () => import("../views/ClientManagement/User/EditUser.vue");
const ViewUser = () => import("../views/ClientManagement/User/ViewUser.vue");
const Banner = () => import("../views/Services/Banner/Banner.vue");
const FeaturedProject = () => import("../views/Services/FeaturedProject/FeaturedProject.vue");
const FeaturedProjectLead = () => import("../views/Leads/FeaturedProjectLead.vue");
const Career = () => import("../views/WebPages/Career/Career.vue");
const Maps = () => import("../views/WebPages/Maps/Maps.vue");
const Article = () => import("../views/WebPages/Article/Article.vue");
const News = () => import("../views/WebPages/News/News.vue");
const Bank = () => import("../views/Services/BankDetails/Bank.vue");
const Loan = () => import("../views/Services/LoanDetails/Loan.vue");
const AddFeaturedProject = () => import("../views/Services/FeaturedProject/AddFeaturedProject.vue");
const NewsComments = () => import("../views/WebPages/News/NewsComments.vue");
const ArticleComments = () => import("../views/WebPages/Article/ArticleComments.vue");
const AddFeaturedProjectBanner = () => import("../views/Services/FeaturedProject/AddFeaturedProjectBanner.vue");
const AddFeaturedProjectAmenities = () => import("../views/Services/FeaturedProject/AddFeaturedProjectAmenities.vue");
const AddFeaturedProjectBrochure = () => import("../views/Services/FeaturedProject/AddFeaturedProjectBrochure.vue");
const AddFeaturedProjectBuilder = () => import("../views/Services/FeaturedProject/AddFeaturedProjectBuilder.vue");
const AddFeaturedProjectFAQ = () => import("../views/Services/FeaturedProject/AddFeaturedProjectFAQ.vue");
const AddFeaturedProjectFloorPlan = () => import("../views/Services/FeaturedProject/AddFeaturedProjectFloorPlan.vue");
const AddFeaturedProjectLoan = () => import("../views/Services/FeaturedProject/AddFeaturedProjectLoan.vue");
const AddFeaturedProjectLocationMap = () => import("../views/Services/FeaturedProject/AddFeaturedProjectLocationMap.vue");
const AddFeaturedProjectSiteMap = () => import("../views/Services/FeaturedProject/AddFeaturedProjectSiteMap.vue");
const AddFeaturedProjectMasterPlan = () => import("../views/Services/FeaturedProject/AddFeaturedProjectMasterPlan.vue");
const CrmEnquiry = () => import("../views/Leads/CrmEnquiry.vue");
const EditProfile = () => import("../views/EditProfile.vue");
const UserProperty = () => import("../views/ClientManagement/User/UserProperty.vue");
const UserLeads = () => import("../views/ClientManagement/User/UserLeads.vue");
const UserBanner = () => import("../views/ClientManagement/User/UserBanner.vue");
const UserCrmEnquiry = () => import("../views/ClientManagement/User/UserCrmEnquiry.vue");
const UsersPayment = () => import("../views/ClientManagement/User/UsersPayment.vue");
const UserInvoice = () => import("../views/ClientManagement/User/UserInvoice.vue");
const Invoices = () => import("../views/ClientManagement/Invoices.vue");
const UserPayments = () => import("../views/ClientManagement/UserPayments");
const SalesEnquiry = () => import("../views/Leads/SalesLead/SalesEnquiry.vue");
const SalesReport = () => import("../views/Leads/SalesLead/SalesReport.vue");
const DmAds = () => import("../views/DmPanel/DmAds.vue");
const DmSeo = () => import("../views/DmPanel/DmSeo.vue");
const DmReport = () => import("../views/DmPanel/DmReport.vue");
const LeadReport = () => import("../views/LeadManagement/LeadReport.vue");
const SoldEnquiry = () => import("../views/SoldServices/SoldEnquiry.vue");
const UserProject = () => import("../views/ClientManagement/User/UserProject.vue");
const UserListing = () => import("../views/ClientManagement/User/UserListing.vue");
// const EmailMarketing = () => import("../views/Marketing/EmailMarketing.vue");
// const EmailLogs = () => import("../views/Marketing/EmailLogs.vue");
// const CreateTemplate = () => import("../views/Marketing/CreateTemplate.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      meta: {
        guestOnly: true,
        layout: "login-layout",
      },
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/list-property',
      name: 'list-property',
      component: ListProperty,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/property-lead',
      name: 'property-lead',
      component: PropertyLeads,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/banner',
      name: 'banner',
      component: Banner,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/banner-lead',
      name: 'banner-lead',
      component: BannerLead,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-banner/:id?',
      name: 'add-banner',
      component: AddBanner,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/list-user',
      name: 'list-user',
      component: ListUser,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-payments',
      name: 'user-payments',
      component: UserPayments,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/edit-user/:id?',
      name: 'edit-user',
      component: EditUser,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/view-user/:id?',
      name: 'view-user',
      component: ViewUser,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-property/:id?',
      name: 'user-property',
      component: UserProperty,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-leads/:id?',
      name: 'user-leads',
      component: UserLeads,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-banner/:id?',
      name: 'user-banner',
      component: UserBanner,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-crm-enquiry/:id?',
      name: 'user-crm-enquiry',
      component: UserCrmEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/users-payment/:id?',
      name: 'users-payment',
      component: UsersPayment,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-invoice/:id?',
      name: 'user-invoice',
      component: UserInvoice,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-project/:id?',
      name: 'user-project',
      component: UserProject,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/user-listing/:id?',
      name: 'user-listing',
      component: UserListing,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/list-project',
      name: 'list-project',
      component: FeaturedProject,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/project-lead',
      name: 'project-lead',
      component: FeaturedProjectLead,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project/:id?',
      name: 'add-featured-project',
      component: AddFeaturedProject,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-banner/:id',
      name: 'add-featured-project-banner',
      component: AddFeaturedProjectBanner,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-builder/:id',
      name: 'add-featured-project-builder',
      component: AddFeaturedProjectBuilder,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-floor-plan/:id',
      name: 'add-featured-project-floor-plan',
      component: AddFeaturedProjectFloorPlan,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-location-map/:id',
      name: 'add-featured-project-location-map',
      component: AddFeaturedProjectLocationMap,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-site-map/:id',
      name: 'add-featured-project-site-map',
      component: AddFeaturedProjectSiteMap,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-master-plan/:id',
      name: 'add-featured-project-master-plan',
      component: AddFeaturedProjectMasterPlan,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-amenities/:id',
      name: 'add-featured-project-amenities',
      component: AddFeaturedProjectAmenities,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-brochure/:id',
      name: 'add-featured-project-brochure',
      component: AddFeaturedProjectBrochure,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-faq/:id',
      name: 'add-featured-project-faq',
      component: AddFeaturedProjectFAQ,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-featured-project-loan/:id',
      name: 'add-featured-project-loan',
      component: AddFeaturedProjectLoan,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/admin-user',
      name: 'admin-user',
      component: AdminUser,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-admin-user/:id?',
      name: 'add-admin-user',
      component: AddAdminUser,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/blog',
      name: 'blog',
      component: Blog,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-blog/:id?',
      name: 'add-blog',
      component: AddBlog,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/blog-comment',
      name: 'blog-comment',
      component: BlogComments,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/lead-enquiry',
      name: 'lead-enquiry',
      component: LeadEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/lead-report',
      name: 'lead-report',
      component: LeadReport,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/sold-enquiry',
      name: 'sold-enquiry',
      component: SoldEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/career',
      name: 'career',
      component: Career,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-career/:id?',
      name: 'add-career',
      component: AddCareer,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/applicant',
      name: 'applicant',
      component: Applicant,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/maps',
      name: 'maps',
      component: Maps,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-maps/:id?',
      name: 'add-maps',
      component: AddMaps,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/crm-roles',
      name: 'crm-roles',
      component: CRMRoles,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/backend-roles',
      name: 'backend-roles',
      component: BackendRoles,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/create-backend-roles/:id?',
      name: 'create-backend-roles',
      component: CreateBackendRole,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/create-crm-roles/:id?',
      name: 'create-crm-roles',
      component: CreateCRMRoles,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/builder-details',
      name: 'builder-details',
      component: BuilderDetails,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-builder/:id?',
      name: 'add-builder',
      component: AddBuilder,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-property/:id?',
      name: 'add-property',
      component: AddProperty,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/uploaded-file',
      name: 'uploaded-file',
      component: UploadedFile,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/state',
      name: 'state',
      component: State,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-state/:id?',
      name: 'add-state',
      component: AddState,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/city',
      name: 'city',
      component: City,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-city/:id?',
      name: 'add-city',
      component: AddCity,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/locality',
      name: 'locality',
      component: Locality,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-locality/:id?',
      name: 'add-locality',
      component: AddLocality,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/project',
      name: 'project',
      component: Project,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-project/:id?',
      name: 'add-project',
      component: AddProject,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/amenities',
      name: 'amenities',
      component: Amenities,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-amenities/:id?',
      name: 'add-amenities',
      component: AddAmenities,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/article',
      name: 'article',
      component: Article,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-article/:id?',
      name: 'add-article',
      component: AddArticle,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/article-comment',
      name: 'article-comment',
      component: ArticleComments,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/review',
      name: 'review',
      component: Review,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/view-review/:id?',
      name: 'view-review',
      component: ViewReview,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-news/:id?',
      name: 'add-news',
      component: AddNews,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/news-comment',
      name: 'news-comment',
      component: NewsComments,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/contact-enquiry',
      name: 'contact-enquiry',
      component: ContactEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/view-contact-enquiry/:id?',
      name: 'view-contact-enquiry',
      component: ViewContactEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/bank',
      name: 'bank',
      component: Bank,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-bank/:id?',
      name: 'add-bank',
      component: AddBank,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/loan',
      name: 'loan',
      component: Loan,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/add-loan/:id?',
      name: 'add-loan',
      component: AddLoan,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },

    {
      path: '/crm-enquiry',
      name: 'crm-enquiry',
      component: CrmEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/sales-enquiry',
      name: 'sales-enquiry',
      component: SalesEnquiry,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/sales-report',
      name: 'sales-report',
      component: SalesReport,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/edit-profile/:id?',
      name: 'edit-profile',
      component: EditProfile,
      meta: {
        layout: "app-layout",
        requiresAuth: true
      }
    },
    {
      path: '/dm-ads',
      name: 'dm-ads',
      component: DmAds,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/dm-seo',
      name: 'dm-seo',
      component: DmSeo,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    {
      path: '/dm-report',
      name: 'dm-report',
      component: DmReport,
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      }
    },
    // {
    //   path: '/email-marketing',
    //   name: 'email-marketing',
    //   component: EmailMarketing,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    //
    // {
    //   path: '/whatsapp-template',
    //   name: 'whatsapp-template',
    //   component: WhatsappTemplate,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    //
    //
    // {
    //   path: '/email-logs/:id?',
    //   name: 'email-logs',
    //   component: EmailLogs,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    // {
    //   path: '/add-message-template/:id?',
    //   name: 'add-message-template',
    //   component: AddWhatsappTemplate,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    // {
    //   path: '/add-whatsapp-template/:id?',
    //   name: 'add-whatsapp-template',
    //   component: AddMessageTemplate,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    // {
    //   path: '/create-template/:id?',
    //   name: 'create-template',
    //   component: CreateTemplate,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    // {
    //   path: '/whatsapp-chat',
    //   name: 'whatsapp-chat',
    //   component: WhatsappChat,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    //
    // {
    //   path: '/create-chat',
    //   name: 'create-chat',
    //   component: CreateChat,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },
    // {
    //   path: '/chat/:id?',
    //   name: 'chat',
    //   component: Chat,
    //   meta: {
    //     requiresAuth: true,
    //     layout: "app-layout",
    //   }
    // },


  ],
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
      return {x: 0, y: 0}
    },

});

export default router;

