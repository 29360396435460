<template>
    <v-app>
        <div>
            <v-navigation-drawer
                class="elevation-3"
                app
                light
                permanent
                v-if="!$store.getters.isMobile"
            >
                <div class="">
                    <v-img :src="require('/src/assets/logo.png')" contain height="90" class="mt-3"></v-img>
                </div>
                <v-divider></v-divider>
                <v-list class="mt-2">
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item @click="$router.push('/dashboard')" v-if="checkUserPermission('dashboard')">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon class="mr-7">mdi-view-dashboard</v-icon>
                                    Dashboard
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-bank'"
                            color="primary"
                            v-if="checkUserPermission('list-property') || checkUserPermission('banner') ||
                                      checkUserPermission('list-project') || checkUserPermission('view-bank')
                                      || checkUserPermission('view-loan-details')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>Services</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'list-property' })"
                                         v-if="checkUserPermission('list-property')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text">List Property
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="$router.push('/banner')" v-if="checkUserPermission('banner')">
                                <v-list-item-content>
                                    <v-list-item-title class="nav-text">
                                        Banner
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'list-project' })"
                                         v-if="checkUserPermission('list-project')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text">Featured Project
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'bank' })"
                                         v-if="checkUserPermission('view-bank')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Bank
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'loan' })"
                                         v-if="checkUserPermission('view-loan-details')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Loan
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-filter'"
                            color="primary"
                            v-if="checkUserPermission('view-lead-report') || checkUserPermission('view-lead-enquiry')">
                            <template v-slot:activator>
                                <v-list-item-title>Lead Management</v-list-item-title>
                            </template>
                            <v-list-item v-on:click="$router.push({ name: 'lead-report' })"
                                         v-if="checkUserPermission('view-lead-report')">
                                <v-list-item-content>
                                    <v-list-item-title class="nav-text">
                                        Lead Report
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'lead-enquiry' })"
                                         v-if="checkUserPermission('view-lead-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text">Lead Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-handshake'"
                            color="primary"
                            v-if="checkUserPermission('view-sold-services')">
                            <template v-slot:activator>
                                <v-list-item-title>Sold Services</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'sold-enquiry' })"
                                         v-if="checkUserPermission('view-sold-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text">Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-supervisor-circle'"
                            color="primary"
                            v-if="checkUserPermission('view-application') ">
                            <template v-slot:activator>
                                <v-list-item-title>HR Panel</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'applicant' })"
                                         v-if="checkUserPermission('view-application')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Job Application
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-search'"
                            color="primary"
                            v-if="checkUserPermission('view-contact-enquiry') || checkUserPermission('view-crm-enquiry')
                            || checkUserPermission('view-sales')">
                            <template v-slot:activator>
                                <v-list-item-title>Enquiry Manager</v-list-item-title>
                            </template>
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'property-lead' })"-->
                            <!--                                         v-if="checkUserPermission('property-lead')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Property Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'banner-lead' })"-->
                            <!--                                         v-if="checkUserPermission('banner-lead')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Banner Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'project-lead' })"-->
                            <!--                                         v-if="checkUserPermission('project-leads')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Featured Project Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <v-list-item link v-on:click="$router.push({ name: 'contact-enquiry' })"
                                         v-if="checkUserPermission('view-contact-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Contact Us Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'crm-enquiry' })"
                                         v-if="checkUserPermission('view-crm-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >CRM Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-group
                                no-action sub-group
                                color="primary"
                                v-if="checkUserPermission('view-sales')">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>Sales</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item
                                    link
                                    v-on:click="$router.push({ name: 'sales-report' })"
                                    v-if="checkUserPermission('view-sales-report')">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="nav-text"
                                        >Report
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    v-on:click="$router.push({ name: 'sales-enquiry' })"
                                    v-if="checkUserPermission('view-sales-enquiry')">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="nav-text"
                                        >Enquiry
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <!--                        <v-list-item link v-on:click="$router.push({ name: 'banner-lead' })">-->
                            <!--                          <v-list-item-content>-->
                            <!--                            <v-list-item-title-->
                            <!--                                class="nav-text">Banner Leads-->
                            <!--                            </v-list-item-title>-->
                            <!--                          </v-list-item-content>-->
                            <!--                        </v-list-item>-->
                        </v-list-group>


                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-web'"
                            color="primary"
                            v-if="checkUserPermission('blog') || checkUserPermission('blog-comment') ||
                                   checkUserPermission('maps') || checkUserPermission('view-news') ||
                                   checkUserPermission('view-article') || checkUserPermission('news-comment')
                                  || checkUserPermission('view-career') || checkUserPermission('article-comment') || checkUserPermission('builder')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>Web Pages</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'blog' })"
                                         v-if="checkUserPermission('blog')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Blogs
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'blog-comment' })"
                                         v-if="checkUserPermission('blog-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Blog Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'news' })"
                                         v-if="checkUserPermission('view-news')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >News
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'news-comment' })"
                                         v-if="checkUserPermission('news-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >News Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'article' })"
                                         v-if="checkUserPermission('view-article')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Article
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'article-comment' })"
                                         v-if="checkUserPermission('article-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Article Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'maps' })"
                                         v-if="checkUserPermission('maps')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Maps
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'career' })"
                                         v-if="checkUserPermission('view-career')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Career
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          <v-list-item link v-on:click="$router.push({ name: 'builder-details' })"
                                       v-if="checkUserPermission('builder')">
                            <v-list-item-content>
                              <v-list-item-title
                                  class="nav-text"
                              >Builder Details
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-desktop-classic'"
                            color="primary"
                            v-if="checkUserPermission('ads-details')">
                            <template v-slot:activator>
                                <v-list-item-title>DM Panel</v-list-item-title>
                            </template>

                            <v-list-item link v-on:click="$router.push({ name: 'dm-ads' })"
                                         v-if="checkUserPermission('ads-details')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Ads
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
<!--                            <v-list-item link v-on:click="$router.push({ name: 'dm-seo' })">-->
<!--                                <v-list-item-content>-->
<!--                                    <v-list-item-title-->
<!--                                        class="nav-text"-->
<!--                                    >SEO-->
<!--                                    </v-list-item-title>-->
<!--                                </v-list-item-content>-->
<!--                            </v-list-item>-->
<!--                            <v-list-item link v-on:click="$router.push({ name: 'dm-report' })">-->
<!--                                <v-list-item-content>-->
<!--                                    <v-list-item-title-->
<!--                                        class="nav-text"-->
<!--                                    >Report-->
<!--                                    </v-list-item-title>-->
<!--                                </v-list-item-content>-->
<!--                            </v-list-item>-->
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-clipboard-account'"
                            color="primary"
                            v-if="checkUserPermission('admin-user') || checkUserPermission('list-user') || checkUserPermission('amenities') ||
                                checkUserPermission('crm-roles') || checkUserPermission('backend-roles') || checkUserPermission('view-review')
                   || checkUserPermission('uploaded-files')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>Master</v-list-item-title>
                            </template>

                            <v-list-item link v-on:click="$router.push({ name: 'admin-user' })"
                                         v-if="checkUserPermission('admin-user')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Admin User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!--                          <v-list-item-->
                            <!--                              link-->
                            <!--                              v-on:click="$router.push({ name: 'list-user' })">-->
                            <!--                            <v-list-item-content>-->
                            <!--                              <v-list-item-title-->
                            <!--                                  class="nav-text"-->
                            <!--                              >Packages-->
                            <!--                              </v-list-item-title>-->
                            <!--                            </v-list-item-content>-->
                            <!--                          </v-list-item>-->
                            <v-list-item link v-on:click="$router.push({ name: 'amenities' })"
                                         v-if="checkUserPermission('amenities')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Amenities
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'review' })"
                                         v-if="checkUserPermission('view-review')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Reviews
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'backend-roles' })"
                                         v-if="checkUserPermission('backend-roles')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Backend Roles
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link
                                         v-on:click="$router.push({ name: 'crm-roles' })"
                                         v-if="checkUserPermission('crm-roles')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >CRM Roles
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link v-on:click="$router.push({ name: 'uploaded-file' })"
                                         v-if="checkUserPermission('uploaded-files')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Uploaded Files
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account'"
                            color="primary"
                            v-if="checkUserPermission('user-payments')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>Client Management</v-list-item-title>
                            </template>
                            <v-list-item
                                link
                                v-on:click="$router.push({ name: 'list-user' })"
                                v-if="checkUserPermission('list-user')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >List User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'invoices' })"
                                         v-if="checkUserPermission('user-invoices')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >User Invoices
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                link
                                v-on:click="$router.push({ name: 'user-payments' })"
                                v-if="checkUserPermission('user-payments')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >User Payments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-map-marker-multiple'"
                            color="primary"
                            v-if="checkUserPermission('state') || checkUserPermission('city') || checkUserPermission('locality') ||
                                checkUserPermission('project')">
                            <template v-slot:activator>
                                <v-list-item-title>Location Master</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'state' })"
                                         v-if="checkUserPermission('state')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >State
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'city' })"
                                         v-if="checkUserPermission('city')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >City
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'locality' })"
                                         v-if="checkUserPermission('locality')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Locality
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'project' })"
                                         v-if="checkUserPermission('project')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                    >Project
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>

<!--                      <v-list-group-->
<!--                          no-action-->
<!--                          :prepend-icon="'mdi-email-arrow-right-outline'"-->
<!--                          color="primary"-->
<!--                          v-if="checkUserPermission('view-lead-report') || checkUserPermission('view-lead-enquiry')">-->
<!--                        <template v-slot:activator>-->
<!--                          <v-list-item-title>Marketing</v-list-item-title>-->
<!--                        </template>-->
<!--                          <v-list-item v-on:click="$router.push({ name: 'email-marketing' })"-->
<!--                                     v-if="checkUserPermission('view-lead-report')">-->
<!--                          <v-list-item-content>-->
<!--                            <v-list-item-title class="nav-text">-->
<!--                              Email Marketing-->
<!--                            </v-list-item-title>-->
<!--                          </v-list-item-content>-->
<!--                        </v-list-item>-->
<!--                          <v-list-item link v-on:click="$router.push({ name: 'email-logs' })"-->
<!--                                     v-if="checkUserPermission('view-lead-enquiry')">-->
<!--                          <v-list-item-content>-->
<!--                            <v-list-item-title-->
<!--                                class="nav-text">Email Logs-->
<!--                            </v-list-item-title>-->
<!--                          </v-list-item-content>-->
<!--                        </v-list-item>-->
<!--                          <v-list-item link v-on:click="$router.push({ name: 'whatsapp-template' })"-->
<!--                                       v-if="checkUserPermission('view-lead-enquiry')">-->
<!--                              <v-list-item-content>-->
<!--                                  <v-list-item-title-->
<!--                                      class="nav-text">Whatsapp Template-->
<!--                                  </v-list-item-title>-->
<!--                              </v-list-item-content>-->
<!--                          </v-list-item>-->
<!--                          <v-list-item link v-on:click="$router.push({ name: 'whatsapp-chat' })"-->
<!--                                       v-if="checkUserPermission('view-lead-enquiry')">-->
<!--                              <v-list-item-content>-->
<!--                                  <v-list-item-title-->
<!--                                      class="nav-text">Whatsapp Chat-->
<!--                                  </v-list-item-title>-->
<!--                              </v-list-item-content>-->
<!--                          </v-list-item>-->

<!--                      </v-list-group>-->
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

            <v-app-bar v-if="$store.getters.isMobile"
                       fixed clipped-left app light flat class="white"
            >
                <v-app-bar-nav-icon large @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

                <v-img @click="$router.push('/')" :src="require('/src/assets/logo.png')" width="30" class="mt-2 ml-n2"></v-img>

                <v-spacer></v-spacer>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                        >
                            <v-icon>mdi-plus</v-icon>&nbsp; Add New
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link :to="{name:'add-property'}" v-if="checkUserPermission('add-property')">
                            <v-list-item-title>Add Property</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-banner'}" v-if="checkUserPermission('add-banner')">
                            <v-list-item-title>Add Banner</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-featured-project'}" v-if="checkUserPermission('add-project')">
                            <v-list-item-title>Add Featured Project</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-admin-user'}" v-if="checkUserPermission('add-admin-user')">
                            <v-list-item-title>Add Admin User</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-blog'}" v-if="checkUserPermission('add-blog')">
                            <v-list-item-title>Add Blog</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-maps'}" v-if="checkUserPermission('add-map')">
                            <v-list-item-title>Add Map</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-article'}" v-if="checkUserPermission('add-article')">
                            <v-list-item-title>Add Article</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-amenities'}" v-if="checkUserPermission('add-amenities')">
                            <v-list-item-title>Add Amenities</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'create-backend-roles'}"
                                     v-if="checkUserPermission('create-backend-roles')">
                            <v-list-item-title>Add Backend Role</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-builder'}" v-if="checkUserPermission('add-builder')">
                            <v-list-item-title>Add Builder</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-state'}" v-if="checkUserPermission('add-state')">
                            <v-list-item-title>Add State</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-city'}" v-if="checkUserPermission('add-city')">
                            <v-list-item-title>Add City</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-locality'}" v-if="checkUserPermission('add-locality')">
                            <v-list-item-title>Add Locality</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-project'}" v-if="checkUserPermission('add-project')">
                            <v-list-item-title>Add Project</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn v-if="!$store.getters.loggedIn" fab @click="dialog = true" x-small light depressed color="white"
                       class="ma-2 mt-3">
                    <v-icon>mdi-login</v-icon>
                </v-btn>
            </v-app-bar>

            <v-app-bar fixed clipped-left app light flat class="b-clr"  v-if="!$store.getters.isMobile">

                <div class="d-flex align-center w-bg">
                    <v-img
                        class="shrink mr-2"
                        contain
                        required=""
                        transition="scale-transition"
                        width="230"
                    />
                </div>

                <v-divider class="mr-5" vertical></v-divider>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-5"
                        >
                            <v-icon>mdi-plus</v-icon>&nbsp; Add New
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link :to="{name:'add-property'}" v-if="checkUserPermission('add-property')">
                            <v-list-item-title>Add Property</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-banner'}" v-if="checkUserPermission('add-banner')">
                            <v-list-item-title>Add Banner</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-featured-project'}" v-if="checkUserPermission('add-project')">
                            <v-list-item-title>Add Featured Project</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-admin-user'}" v-if="checkUserPermission('add-admin-user')">
                            <v-list-item-title>Add Admin User</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-blog'}" v-if="checkUserPermission('add-blog')">
                            <v-list-item-title>Add Blog</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-maps'}" v-if="checkUserPermission('add-map')">
                            <v-list-item-title>Add Map</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-article'}" v-if="checkUserPermission('add-article')">
                            <v-list-item-title>Add Article</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-amenities'}" v-if="checkUserPermission('add-amenities')">
                            <v-list-item-title>Add Amenities</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'create-backend-roles'}"
                                     v-if="checkUserPermission('create-backend-roles')">
                            <v-list-item-title>Add Backend Role</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-builder'}" v-if="checkUserPermission('add-builder')">
                            <v-list-item-title>Add Builder</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-state'}" v-if="checkUserPermission('add-state')">
                            <v-list-item-title>Add State</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-city'}" v-if="checkUserPermission('add-city')">
                            <v-list-item-title>Add City</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-locality'}" v-if="checkUserPermission('add-locality')">
                            <v-list-item-title>Add Locality</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{name:'add-project'}" v-if="checkUserPermission('add-project')">
                            <v-list-item-title>Add Project</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <!---account menu--->
                <v-menu bottom min-width="200px" rounded offset-y class="ml-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon x-large v-on="on">
                            <v-avatar color="blue" size="36">
                                <span class="white--text">{{ user.initials }}</span>
                            </v-avatar>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list-item-content class="justify-center">
                            <div class="mx-auto text-center">
                                <v-avatar color="blue" size="36px" class="mb-5">
                                    <span class="white--text">{{ user.initials }}</span>
                                </v-avatar>
                                <h3>{{ user.fullName }}</h3>
                                <p class="text-caption mt-1">
                                    {{ user.email }}
                                </p>
                                <v-divider class="my-3"></v-divider>
                                <v-btn depressed rounded text @click="$router.push('/edit-profile')">
                                    Edit Profile
                                </v-btn>
                                <v-divider class="my-3"></v-divider>
                                <v-btn :to="{name : 'logout'}" depressed rounded text> Logout</v-btn>
                            </div>
                        </v-list-item-content>
                    </v-card>
                </v-menu>

            </v-app-bar>

            <v-navigation-drawer
                v-model="drawer"
                absolute
                v-scroll
                temporary
                width="280"
                height="100vh"
            >
                <v-list nav dense>
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item v-if="$store.getters.loggedIn">
                            <v-list-item-avatar color="blue" size="50px">
                                <span class="white--text">{{ user.initials }}</span>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="body-1">
                                    {{ this.$store.state.firstName + " " + this.$store.state.lastName }}
                                </v-list-item-title>
                                <router-link :to="'/edit-profile'">
                                    <v-list-item-subtitle class="body-2">Edit Profile</v-list-item-subtitle>
                                </router-link>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="$router.push('/dashboard')" v-if="checkUserPermission('dashboard')">
                            <v-list-item-content>
                                <v-list-item-title class="body-2">
                                    <v-icon class="mr-7">mdi-view-dashboard</v-icon>
                                    Dashboard
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-bank'"
                            color="primary"
                            v-if="checkUserPermission('list-property') || checkUserPermission('banner') ||
                                      checkUserPermission('list-project') || checkUserPermission('view-bank')
                                      || checkUserPermission('view-loan-details')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Services</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'list-property' })"
                                         v-if="checkUserPermission('list-property')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2">List Property
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="$router.push('/banner')" v-if="checkUserPermission('banner')">
                                <v-list-item-content>
                                    <v-list-item-title class="nav-text body-2">
                                        Banner
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'list-project' })"
                                         v-if="checkUserPermission('list-project')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2">Featured Project
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'bank' })"
                                         v-if="checkUserPermission('view-bank')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Bank
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'loan' })"
                                         v-if="checkUserPermission('view-loan-details')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Loan
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-filter'"
                            color="primary"
                            v-if="checkUserPermission('view-lead-report') || checkUserPermission('view-lead-enquiry')">
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Lead Management</v-list-item-title>
                            </template>
                            <v-list-item v-on:click="$router.push({ name: 'lead-report' })"
                                         v-if="checkUserPermission('view-lead-report')">
                                <v-list-item-content>
                                    <v-list-item-title class="nav-text body-2">
                                        Lead Report
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'lead-enquiry' })"
                                         v-if="checkUserPermission('view-lead-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2">Lead Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-supervisor-circle'"
                            color="primary"
                            v-if="checkUserPermission('view-application') ">
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">HR Panel</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'applicant' })"
                                         v-if="checkUserPermission('view-application')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Job Application
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account-search'"
                            color="primary"
                            v-if="checkUserPermission('view-contact-enquiry') || checkUserPermission('view-crm-enquiry')
                            || checkUserPermission('view-sales')">
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Enquiry Manager</v-list-item-title>
                            </template>
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'property-lead' })"-->
                            <!--                                         v-if="checkUserPermission('property-lead')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Property Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'banner-lead' })"-->
                            <!--                                         v-if="checkUserPermission('banner-lead')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Banner Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <!--                            <v-list-item link v-on:click="$router.push({ name: 'project-lead' })"-->
                            <!--                                         v-if="checkUserPermission('project-leads')">-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title-->
                            <!--                                        class="nav-text">Featured Project Leads-->
                            <!--                                    </v-list-item-title>-->
                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                            <v-list-item link v-on:click="$router.push({ name: 'contact-enquiry' })"
                                         v-if="checkUserPermission('view-contact-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Contact Us Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'crm-enquiry' })"
                                         v-if="checkUserPermission('view-crm-enquiry')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >CRM Enquiry
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-group
                                no-action sub-group
                                color="primary"
                                v-if="checkUserPermission('view-sales')">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title class="body-2">Sales</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item
                                    link
                                    v-on:click="$router.push({ name: 'sales-report' })"
                                    v-if="checkUserPermission('view-sales-report')">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="nav-text body-2"
                                        >Report
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    v-on:click="$router.push({ name: 'sales-enquiry' })"
                                    v-if="checkUserPermission('view-sales-enquiry')">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="nav-text body-2"
                                        >Enquiry
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <!--                        <v-list-item link v-on:click="$router.push({ name: 'banner-lead' })">-->
                            <!--                          <v-list-item-content>-->
                            <!--                            <v-list-item-title-->
                            <!--                                class="nav-text">Banner Leads-->
                            <!--                            </v-list-item-title>-->
                            <!--                          </v-list-item-content>-->
                            <!--                        </v-list-item>-->
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-web'"
                            color="primary"
                            v-if="checkUserPermission('blog') || checkUserPermission('blog-comment') ||
                                   checkUserPermission('maps') || checkUserPermission('view-news') ||
                                   checkUserPermission('view-article') || checkUserPermission('news-comment')
                                  || checkUserPermission('view-career') || checkUserPermission('article-comment') || checkUserPermission('builder')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Web Pages</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'blog' })"
                                         v-if="checkUserPermission('blog')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Blogs
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'blog-comment' })"
                                         v-if="checkUserPermission('blog-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Blog Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'news' })"
                                         v-if="checkUserPermission('view-news')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >News
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'news-comment' })"
                                         v-if="checkUserPermission('news-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >News Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'article' })"
                                         v-if="checkUserPermission('view-article')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Article
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'article-comment' })"
                                         v-if="checkUserPermission('article-comment')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Article Comments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'maps' })"
                                         v-if="checkUserPermission('maps')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Maps
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'career' })"
                                         v-if="checkUserPermission('view-career')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Career
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          <v-list-item link v-on:click="$router.push({ name: 'builder-details' })"
                                       v-if="checkUserPermission('builder')">
                            <v-list-item-content>
                              <v-list-item-title
                                  class="nav-text body-2"
                              >Builder Details
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-desktop-classic'"
                            color="primary">
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">DM Panel</v-list-item-title>
                            </template>

                            <v-list-item link v-on:click="$router.push({ name: 'dm-ads' })">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Ads
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'dm-seo' })">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >SEO
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'dm-report' })">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Report
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-clipboard-account'"
                            color="primary"
                            v-if="checkUserPermission('admin-user') || checkUserPermission('list-user') || checkUserPermission('amenities') ||
                                checkUserPermission('crm-roles') || checkUserPermission('backend-roles') || checkUserPermission('view-review')
                   || checkUserPermission('uploaded-files')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Master</v-list-item-title>
                            </template>

                            <v-list-item link v-on:click="$router.push({ name: 'admin-user' })"
                                         v-if="checkUserPermission('admin-user')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Admin User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!--                          <v-list-item-->
                            <!--                              link-->
                            <!--                              v-on:click="$router.push({ name: 'list-user' })">-->
                            <!--                            <v-list-item-content>-->
                            <!--                              <v-list-item-title-->
                            <!--                                  class="nav-text"-->
                            <!--                              >Packages-->
                            <!--                              </v-list-item-title>-->
                            <!--                            </v-list-item-content>-->
                            <!--                          </v-list-item>-->
                            <v-list-item link v-on:click="$router.push({ name: 'amenities' })"
                                         v-if="checkUserPermission('amenities')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Amenities
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'review' })"
                                         v-if="checkUserPermission('view-review')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Reviews
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'backend-roles' })"
                                         v-if="checkUserPermission('backend-roles')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Backend Roles
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link
                                         v-on:click="$router.push({ name: 'crm-roles' })"
                                         v-if="checkUserPermission('crm-roles')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >CRM Roles
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'uploaded-file' })"
                                         v-if="checkUserPermission('uploaded-files')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Uploaded Files
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-account'"
                            color="primary"
                            v-if="checkUserPermission('user-payments')"
                        >
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Client Management</v-list-item-title>
                            </template>
                            <v-list-item
                                link
                                v-on:click="$router.push({ name: 'list-user' })"
                                v-if="checkUserPermission('list-user')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >List User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'invoices' })"
                                         v-if="checkUserPermission('user-invoices')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >User Invoices
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                link
                                v-on:click="$router.push({ name: 'user-payments' })"
                                v-if="checkUserPermission('user-payments')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >User Payments
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group
                            no-action
                            :prepend-icon="'mdi-map-marker-multiple'"
                            color="primary"
                            v-if="checkUserPermission('state') || checkUserPermission('city') || checkUserPermission('locality') ||
                                checkUserPermission('project')">
                            <template v-slot:activator>
                                <v-list-item-title class="body-2">Location Master</v-list-item-title>
                            </template>
                            <v-list-item link v-on:click="$router.push({ name: 'state' })"
                                         v-if="checkUserPermission('state')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >State
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'city' })"
                                         v-if="checkUserPermission('city')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >City
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'locality' })"
                                         v-if="checkUserPermission('locality')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Locality
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push({ name: 'project' })"
                                         v-if="checkUserPermission('project')">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text body-2"
                                    >Project
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-divider v-if="$store.getters.loggedIn"></v-divider>
                        <v-list-item :to="{name : 'logout'}" v-if="$store.getters.loggedIn">
                            <v-list-item-title class="body-2">
                                <v-icon class="mr-7 ml-1">mdi-logout</v-icon>
                                Logout
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider v-if="$store.getters.loggedIn"></v-divider>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

        </div>
        <v-main>
            <router-view/>
        </v-main>

        <notifications position="top center" width="500" closeOnClick/>

    </v-app>
</template>

<script>
import BackendAuth from "@/apis/BackendAuth";

export default {
    name: "AppLayout",

    mounted() {
        this.speakerInitials();
        this.user.fullName =
            this.$store.state.firstName + " " + this.$store.state.lastName;
        this.user.email = this.$store.state.email;
    },

    data: () => ({
        drawer: false,
        group: null,
        fav: true,
        menu: false,
        message: false,
        hints: true,
        user: {
            initials: "",
            fullName: "",
            email: "",
        },
        selectedItem: 0,
        subSelectedItem: 0,
    }),

    methods: {
        logout() {
            BackendAuth.logout()
                .then((response) => {
                    console.log(response);
                    localStorage.removeItem("adminLogin");
                    localStorage.removeItem("ability");
                    window.location.href = '/';
                })
                .catch((error) => {

                    console.log(error);
                    localStorage.removeItem("adminLogin");
                    localStorage.removeItem("ability");
                    window.location.href = '/';
                });
        },

        speakerInitials() {
            var speaker =
                this.$store.state.firstName + " " + this.$store.state.lastName;
            const name = speaker.split(" ");
            this.user.initials = `${name[0].charAt(0)}${
                name[1] ? name[1].charAt(0) : ""
            }`;
        },

        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        checkUserPermission: function (needle) {
            var ability = this.$store.state.ability;
            var length = ability.length;
            for (var i = 0; i < length; i++) {
                if (ability[i] == needle) return true;
            }
            return false;
        },


    },

    watch: {
        group() {
            this.drawer = false;
        },
    },
};
</script>
